
import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";
import Tour_SubBanner from "../components/Tour_SubBanner";
import ServiceAreaSix from "../components/ServiceAreaSix";
import Tour_IndustryInsights from "../components/Tour_IndustryInsights";
import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FaqAreaThree from "../components/FaqAreaThree";


const bannerData = [
    {
      subtitle: 'Staff Database with RosterInsight',
      title: 'Unlimited staff management at your fingertips.',
      description:
        'Easily manage an unlimited number of staff members, storing vital information such as contact details, job roles, qualifications, and more.',
      image: 'assets/img/banner/Schedule.jpg',
      buttons: [
        { text: 'Try For Free', link: '/contact', className: 'btn btn-border-base' },
        { text: 'Book A Demo', link: '/contact', className: 'btn btn-black' }
      ]
    }
  ];

const testimonialData = [
    {
      name: 'John Doe',
      designation: 'Operations Manager',
      review: 'RosterInsight has transformed our scheduling process, making it more efficient and transparent. The time clock feature ensures accurate payroll, and the support team is always responsive to our needs.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/1.png',
      rating: 5
    },
    {
      name: 'Jane Smith',
      designation: 'HR Director',
      review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/2.png',
      rating: 5
    },
  ];

  const services = [
    
    {
      title: 'Streamlined Operations',
      description:
        'Automating scheduling and time tracking reduces manual workloads, allowing managers to allocate time to strategic tasks.',
      image: 'assets/img/home-6/1.png',
      link: '/service-details',
    },
    {
      title: 'Enhanced Employee Satisfaction',
      description:
        'Providing a user-friendly platform for shift management and time tracking boosts employee engagement and morale.',
      image: 'assets/img/home-6/2.png',
      link: '/service-details',
    },
    {
      title: 'Cost Optimization',
      description:
        'Efficient scheduling and payroll management contribute to significant cost savings, enhancing overall profitability.',
      image: 'assets/img/home-6/3.png',
      link: '/service-details',
    },
  ];

  const featuredata = [
    {
      id: 1,
      subtitle: "Staff Database Management",
      title: "Effortlessly Manage Your Workforce",
      description: "Our staff management software provides an unlimited database for your employees, with free user access to manage thousands of profiles including contact details, positions, qualifications, and more.",
      image: "assets/img/features/staff-database.png", // Replace with actual image path
      listItems: [
        "Unlimited staff database for recruitment without limitations",
        "Centralized employee data in one location",
        "Detailed employee profiles including skills, qualifications, and health info",
        "Import employee data via CSV files"
      ]
    },
    {
      id: 2,
      subtitle: "Shift Allocation & Scheduling",
      title: "Efficient Scheduling with Position Matching",
      description: "Easily assign shifts to employees based on position requirements and availability. Track attendance and manage absences seamlessly for an efficient payroll process.",
      image: "assets/img/features/shift-allocation.png", // Replace with actual image path
      listItems: [
        "Quickly fill shifts by matching employees to required positions",
        "Track employee time and attendance for payroll",
        "Manage absences efficiently"
      ]
    },
    {
      id: 3,
      subtitle: "Action List",
      title: "Stay on Top of Employee Certifications & Expirations",
      description: "The Action List feature notifies you about expiring qualifications, visas, and other important documentation, helping you ensure compliance and keep tasks on track.",
      image: "assets/img/features/action-list.png", // Replace with actual image path
      listItems: [
        "Automated notifications for expiring qualifications and documentation",
        "Reduce administrative workload with automated task management",
        "Ensure compliance with industry regulations"
      ]
    },
    {
      id: 4,
      subtitle: "Custom Filters",
      title: "Find the Right Employees Fast",
      description: "Use custom filters to quickly search and find employees. Tailor the staff list to your specific needs and save filtered views for future use.",
      image: "assets/img/features/custom-filters.png", // Replace with actual image path
      listItems: [
        "Create custom filters to suit your staffing needs",
        "Filter by location, time, and other key criteria",
        "Save and quickly access filtered views for future use"
      ]
    }
  ];
  

  const ctaActionData = {
    subtitle: "",
    title: "Manage Unlimited Employee Data. Streamline Shift Allocation.",
    // content:
    //   "Experience the benefits of RosterInsight's Employee Scheduling Software. Contact us today to schedule a demo or start your free trial.",
    buttons: [
      // {
      //   label: "Contact Us",
      //   link: "/contact",
      //   className: "btn btn-border-base",
      // },
      {
        label: "Start Free Trial",
        link: "/contact",
        className: "btn btn-black",
        style: { marginLeft: "8px" },
      },
    ],
  };


  const industrydata = [
    { title: "Automate 4-on-4-off Scheduling: Why It Works" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Top 8 Reasons to Use a GPS Time Clock App" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Top 8 Reasons to Use a GPS Time Clock App" ,imgSrc: "assets/img/blog/1.png",link: "/"},
  ];

  const faqData = [
    {
      id: 1,
      question: "What is staff scheduling software?",
      answer: "Staff scheduling software streamlines your employee scheduling by automating processes to minimize administrative workload. It enables you to create complex schedules in seconds, quickly fill shifts with the right staff, track attendance, and manage costs to maximize profits."
    },
    {
      id: 2,
      question: "Can I track payroll information with RosterInsight?",
      answer: "Absolutely! You can set custom pay rates for each shift, and when your employees clock in via the GPS Time Clock app, timesheets with payroll information will be automatically generated."
    },
    {
      id: 3,
      question: "Can I manage break times with RosterInsight?",
      answer: "Yes! You can configure personalized break rules, and your employees can easily clock in and out for breaks through the GPS Time Clock app on their mobile devices."
    },
    {
      id: 4,
      question: "What additional benefits does RosterInsight offer?",
      answer: "With RosterInsight, you can efficiently manage absences, track holiday pay, and set custom working time rules to ensure full compliance with labor regulations."
    },
    {
      id: 5,
      question: "Is RosterInsight suitable for staffing agencies?",
      answer: "Yes, RosterInsight is specifically designed for staffing agencies. We offer tailored features like Pay & Charge and the Client Portal, which are ideal for managing staffing needs effectively."
    },
    {
      id: 6,
      question: "How is RosterInsight different from other staff management systems?",
      answer: "RosterInsight stands out by offering advanced scheduling features and optimization for large enterprises, reducing administrative overhead. At the same time, it's user-friendly, only charging for scheduled hours, making it a risk-free solution for managing temporary workers and supporting growth for mid-sized businesses."
    }
  ];
  

const Tour_HrManagement = () => {

  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <Tour_SubBanner bannerData={bannerData}/>

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      <ServiceAreaSix services={services} title="Why Use InsightRota App? Top 3 Benefits"/>

      {/* Testimonial One */}
      <TestimonialOne testimonialData={testimonialData}/>

      <Tour_PricingPlan data={ctaActionData}/>
      
      
      <Tour_IndustryInsights data={industrydata} title="Industries Finding Success with Our Staff Scheduling Software"/>

      {/* Blog Area Two */}
      <BlogAreaTwo />

      <FaqAreaOne data={faqData}/>

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Tour_HrManagement;
