import React from 'react';
import { Link } from 'react-router-dom';

const ServiceAreaSix = ({services , title}) => {
  return (
    <>
      {/*=================== service area six start ===================*/}
      <div
        className="service-area bg-cover pd-top-90 pd-bottom-90 pd-top-110 pd-bottom-90"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title text-center">
                {/* <h6 className="bg-none color-base mb-3">Our Best service</h6> */}
                <h2 className="title">{title}</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            {services?.map((service, index) => (
              <div className="col-lg-4 col-md-6 d-flex" key={index}>
                <div className="single-service-inner style-3">
                  <div className="thumb-img text-end">
                    <img src={service.image} alt={service.title} />
                  </div>
                  <div className="details">
                    <h5>
                      <Link to={service.link}>{service.title}</Link>
                    </h5>
                    <p className="mb-0">{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaSix;
