import React from 'react';
import NavBar from '../components/NavBar';
import Breadcrumb from '../components/Breadcrumb';
import FooterOne from '../components/FooterOne';
import PriceAreaOne from '../components/PriceAreaOne';
import NavbarTwo from '../components/NavbarTwo';
import FooterTwo from '../components/FooterTwo';

const PriceOne = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo />

      {/* Breadcrumb */}
      <Breadcrumb title={'Pricing'} />

      {/* PriceAreaOne */}
      <PriceAreaOne />

      {/* Footer One */}
      <FooterTwo/>
    </>
  );
};

export default PriceOne;
