import React from 'react';
import { FaLink } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ProjectAreaThree = () => {
  return (
    <div className="project-section project-section_3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h6 className="color-base bg-none mb-3">Our Project</h6>
              <h2 className="title">
                Some of our Finished Projects That will Amaze Your Industry
              </h2>
            </div>
          </div>
        </div>
        <nav className="text-center">
          <div
            className="nav nav-tabs project-nav-tab style-2 d-inline-flex mb-5"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="nav-tabs1-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs1"
              type="button"
              role="tab"
              aria-controls="nav-tabs1"
              aria-selected="true"
            >
              All
            </button>
            <button
              className="nav-link"
              id="nav-tabs2-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs2"
              type="button"
              role="tab"
              aria-controls="nav-tabs2"
              aria-selected="false"
            >
              Technology
            </button>
            <button
              className="nav-link"
              id="nav-tabs3-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs3"
              type="button"
              role="tab"
              aria-controls="nav-tabs3"
              aria-selected="false"
            >
              Business
            </button>
            <button
              className="nav-link"
              id="nav-tabs4-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs4"
              type="button"
              role="tab"
              aria-controls="nav-tabs4"
              aria-selected="false"
            >
              Marketing
            </button>
          </div>
        </nav>
      </div>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-tabs1"
          role="tabpanel"
          aria-labelledby="nav-tabs1-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/12.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/13.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-tabs2"
          role="tabpanel"
          aria-labelledby="nav-tabs2-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/12.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/13.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-tabs3"
          role="tabpanel"
          aria-labelledby="nav-tabs3-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/12.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/13.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-tabs4"
          role="tabpanel"
          aria-labelledby="nav-tabs4-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/12.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/13.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/10.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/11.png" alt="img" />
                <div className="details text-start">
                  <p>Hardware,Tech Solve</p>
                  <h5>GreenRay Solar</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dictumst curabitur
                    diam ut est pulvinar
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaThree;
