
import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";
import Tour_SubBanner from "../components/Tour_SubBanner";
import ServiceAreaSix from "../components/ServiceAreaSix";
import Tour_IndustryInsights from "../components/Tour_IndustryInsights";
import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FaqAreaThree from "../components/FaqAreaThree";


const bannerData = [
    {
      subtitle: 'Pay & Charge with RosterInsight',
      title: 'Payroll and Billing Management Software',
      description:
        'Configure custom pay rates to control labor costs and ensure profitability while accurately billing clients.',
      image: 'assets/img/banner/Schedule.jpg',
      buttons: [
        { text: 'Try For Free', link: '/contact', className: 'btn btn-border-base' },
        { text: 'Book A Demo', link: '/contact', className: 'btn btn-black' }
      ]
    }
  ];

const testimonialData = [
    {
      name: 'John Doe',
      designation: 'Operations Director at FGH Security',
      review: 'Roster Insights efficiently manages all employee and customer data. It automates calculations for billing clients, and we can always trust that the data is accurate.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/1.png',
      rating: 5
    },
    {
      name: 'Jane Smith',
      designation: 'HR Director',
      review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/2.png',
      rating: 5
    },
  ];

  const services = [
    
    {
      title: 'Streamlined Operations',
      description:
        'Automating scheduling and time tracking reduces manual workloads, allowing managers to allocate time to strategic tasks.',
      image: 'assets/img/home-6/1.png',
      link: '/service-details',
    },
    {
      title: 'Enhanced Employee Satisfaction',
      description:
        'Providing a user-friendly platform for shift management and time tracking boosts employee engagement and morale.',
      image: 'assets/img/home-6/2.png',
      link: '/service-details',
    },
    {
      title: 'Cost Optimization',
      description:
        'Efficient scheduling and payroll management contribute to significant cost savings, enhancing overall profitability.',
      image: 'assets/img/home-6/3.png',
      link: '/service-details',
    },
  ];

  const featuredata = [
    {
      id: 1,
      subtitle: "Employee Timesheets",
      title: "Automated Payroll Management System",
      description: "Set up custom pay rates for each shift and let the software track payroll for you. Employees' salaries are automatically calculated when they clock in and out using the Time Clock app.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Custom hourly or fixed pay rates",
        "Automated payroll calculations",
        "Eliminate human errors"
      ]
    },
    {
      id: 2,
      subtitle: "Invoicing",
      title: "Efficient Time Billing Software",
      description: "Set custom bill rates for each shift, and generate invoices automatically. Export invoices in bulk to streamline your business management.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Maintain healthy profit margins",
        "Reduce management overhead costs",
        "Prevent manual errors"
      ]
    },
    {
      id: 3,
      subtitle: "Complex Rates",
      title: "Overtime Calculator with Flexible Rules",
      description: "Set up multiple Pay & Charge rules for each shift, with automatic calculations for overtime, night shifts, weekends, and more. Streamline payroll and billing processes.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Create multiple Pay & Charge rules for each shift",
        "Automated salary and billing rate calculations",
        "Avoid manual errors and inefficiencies"
      ]
    },
    {
      id: 4,
      subtitle: "Client Portal",
      title: "Client Portal with Real-Time Data",
      description: "Provide real-time updates on schedules, employees, and billing information. Allow clients to approve timesheets and view transparent bill rates for each shift.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Share scheduling and staffing information",
        "Enable in-app timesheet approvals",
        "Display billing rates for each shift"
      ]
    }
  ];
  

  const ctaActionData = {
    subtitle: "",
    title: "Boost financial efficiency in your staffing operations.",
    // content:
    //   "Experience the benefits of RosterInsight's Employee Scheduling Software. Contact us today to schedule a demo or start your free trial.",
    buttons: [
      {
        label: "Contact Us",
        link: "/contact",
        className: "btn btn-border-base",
      },
      {
        label: "Start Free Trial",
        link: "/contact",
        className: "btn btn-black",
        style: { marginLeft: "8px" },
      },
    ],
  };


  const industrydata = [
    { title: "How to Select the Best Clock-In App for Your Employees" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Essential Features for Your Rota Software" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "How Staffing Agencies Can Streamline Payroll Tracking with Automation" ,imgSrc: "assets/img/blog/1.png",link: "/"},
  ];

  const faqData = [
    {
      question: "What is staff scheduling software?",
      answer: "Staff scheduling software simplifies the scheduling process by automating tasks to reduce administrative overhead. It allows you to quickly create detailed schedules, assign shifts efficiently, track time and attendance, and control costs to maintain healthy profit margins."
    },
    {
      question: "Can I track payroll information with Roster Insights?",
      answer: "Yes! You can set up customized pay rates for each shift. As employees clock in using the GPS-enabled Time Clock app, their timesheets, including payroll data, are automatically generated."
    },
    {
      question: "Can I manage break times with Roster Insights?",
      answer: "Absolutely! You can configure customized break rules, allowing employees to clock in and out for breaks directly from their mobile devices using the GPS Time Clock."
    },
    {
      question: "What additional features does Roster Insights offer?",
      answer: "In addition to tracking absences and holiday pay, Roster Insights allows you to create custom working time rules, ensuring your operations remain compliant with labor laws."
    },
    {
      question: "Is Roster Insights a good solution for staffing agencies?",
      answer: "Yes, Roster Insights is an excellent choice for staffing agencies. It includes specialized features like Pay & Charge and a Client Portal, which are tailored to meet the unique needs of staffing businesses."
    },
    {
      question: "How does Roster Insights differ from other staff management systems?",
      answer: "Roster Insights provides a sophisticated yet easy-to-use scheduling solution. It offers advanced optimization features for large enterprises while being simple for mid-sized companies to implement. You only pay for the hours scheduled, which makes it an ideal and risk-free solution for managing temporary workforces."
    }
  ];
  

const Tour_PayandCharge = () => {

  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <Tour_SubBanner bannerData={bannerData}/>

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      {/* <ServiceAreaSix services={services} title="Why Use InsightRota App? Top 3 Benefits"/> */}

      {/* Testimonial One */}
      <TestimonialOne testimonialData={testimonialData}/>

      <Tour_PricingPlan data={ctaActionData}/>
      
      
      <Tour_IndustryInsights data={industrydata} title="Industries Finding Success with Our Staff Scheduling Software"/>

      {/* Blog Area Two */}
      {/* <BlogAreaTwo /> */}

      <FaqAreaOne data={faqData}/>

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Tour_PayandCharge;
