import React, { useState } from 'react';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const industries = [
  {
    id: 1,
    title: "Security Staffing",
    description: "Providing skilled and reliable staff for security services."
  },
  {
    id: 2,
    title: "Event Staffing",
    description: "Efficient management of staff for events of any scale."
  },
  {
    id: 3,
    title: "FM & Cleaning Staff",
    description: "Easy to use for cleaners. Shared devices for staff."
  },
  {
    id: 4,
    title: "Healthcare Staffing",
    description: "Assign or invite qualified staff to the right shifts.",
    image: "path/to/healthcare-sector.png" // Replace with actual image path
  },
  {
    id: 5,
    title: "Temporary Staffing",
    description: "Keep a happy flexible staff pool and margins at the same time.",
    image: "path/to/staff.png" // Replace with actual image path
  },
  {
    id: 6,
    title: "Stadia and Venues",
    description: "Combine the Event Staffing module with shift planning tools to erase the chaos.",
    image: "path/to/stadia-sector.png" // Replace with actual image path
  },
  {
    id: 7,
    title: "Retail Staffing",
    description: "Transformative toolset from Shift Patterns automation to faster payroll.",
    image: "path/to/retail-sector.png" // Replace with actual image path
  },
  {
    id: 8,
    title: "Hospitality Sector",
    description: "Schedule a quarter or even a year ahead with unique calendar views and patterns.",
    image: "path/to/hospitality-sector.png" // Replace with actual image path
  },
  {
    id: 9,
    title: "Warehousing and Logistics",
    description: "Used by logistics firms with thousands of staff to handle open shifts better.",
    image: "path/to/warehousing.png" // Replace with actual image path
  },
  {
    id: 10,
    title: "Childcare",
    description: "As demand for childcare continues to grow, our software helps you efficiently manage and expand your staffing rotas."
  }
];


const NavbarTwo = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll('.menu-item-has-children > a');
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector('.sub-menu')
          .classList.toggle('active');
        this.classList.toggle('open');
      };
    }
  }

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedData = chunkArray(industries, 5);


  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? 'td-search-popup active' : 'td-search-popup '}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? 'body-overlay active' : 'body-overlay'}
        id="body-overlay"
      ></div>
      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area_2 navbar-area-2 navbar-expand-lg" 
      style={{position:'fixed', top:'0' , left:'0' , width:'100%' , backdropFilter:'blur(16px)'}}
      >
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? 'menu toggle-btn d-block d-lg-none open'
                  : 'menu toggle-btn d-block d-lg-none'
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/" style={{color:'black'}}>
              RosterInsight
              {/* <img src="assets/img/logo.png" alt="img" /> */}
            </Link>
          </div>
          <div className="nav-right-part nav-right-part-mobile">
            <span className="search-bar-btn" onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
          <div
            className={
              active
                ? 'collapse navbar-collapse sopen'
                : 'collapse navbar-collapse'
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li className="menu-item-has-children mega-menu">
                <Link to="#">Tour</Link>
                <div className="sub-menu">
                    <div className="row">
                      <div className="mb-lg-4 col-lg-4 col-xl-3">
                        <a href='' style={{marginLeft:'36px'}}>Schedule</a>
                        <ul>
                          <li>
                            <Link to="/employee-scheduling-software">Employee Scheduling</Link>
                            <p>Streamline scheduling, create complex shift patterns and send automated notifications.</p>
                          </li>
                          <li>
                            <Link to="/holiday-planner-leave-planner">Absence Management</Link>
                            <p>Approve absences and holiday requests on RosterInsight mobile app.</p>
                          </li>
                        </ul>
                      </div>
                      <div className="mb-lg-4 col-lg-4 col-xl-3">
                        <a href='' style={{marginLeft:'36px'}}>Track</a>
                        <ul>
                        <li>
                            <Link to="/time-tracking-software">Time Clock and Timesheets</Link>
                            <p>Use GPS Time tracking for accurate data and automate payroll.</p>
                          </li>
                          <li>
                            <Link to="/payroll-charge-calculator-software">Pay and Charge Manager</Link>
                            <p>Manage hourly pay & charge rates for a healthy operational revenue.</p>
                          </li>
                        </ul>
                      </div>
                      <div className="mb-lg-4 col-lg-4 col-xl-3">
                        <a href='' style={{marginLeft:'36px'}}>Manage</a>
                        <ul>
                          <li>
                            <Link to="/hr-management-software">HR Data and Compliance</Link>
                            <p>Store staff contact information and qualifications. Ensure compliance with labor laws.</p>
                          </li>
                          <li>
                            <Link to="/integrations">Integrations Marketplace</Link>
                            <p>Connect your RosterInsight environment to other industry-leading software platforms.</p>
                          </li>
                        </ul>
                      </div>
                      <div className="mb-lg-4 col-lg-4 col-xl-3">
                        <a href='' style={{marginLeft:'36px'}}>Enhance</a>
                        <ul>
                          <li>
                            <Link to="/event-staff-management">Event Management</Link>
                            <p>Staff recurring or one-off events with ease using Events Calendar.</p>
                          </li>
                          <li>
                            <Link to="/nfc-time-clock">Guard Tours</Link>
                            <p>Use NFC tags and iBeacon to provide time-tracked guard tours for your clients.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                {/* <ul className="sub-menu">
                  <li>
                    <Link to="/">IT / Softwer Agency</Link>
                  </li>
                  <li>
                    <Link to="/index-2">SaaS App Landing</Link>
                  </li>
                  <li>
                    <Link to="/index-3">Payments Solution</Link>
                  </li>
                  <li>
                    <Link to="/index-4">Software Company</Link>
                  </li>
                  <li>
                    <Link to="/index-5">Artificial Intelligence</Link>
                  </li>
                  <li>
                    <Link to="/index-6">NFT Service</Link>
                  </li>
                  <li>
                    <Link to="/index-7">Cyber Security</Link>
                  </li>
                  <li>
                    <Link to="/index-8">Hardware Service</Link>
                  </li>
                  <li>
                    <Link to="/index-9">ICO Landing</Link>
                  </li>
                  <li>
                    <Link to="/index-10">Personal Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/index-11">SEO Service</Link>
                  </li>
                </ul> */}
              </li>
              <li className="menu-item-has-children mega-menu">
                <Link to="#">Industries & Clients</Link>
                <div className="sub-menu">
                  <div className="row">
                  {chunkedData.map((chunk, index) => (
                    <div key={index} className="mb-lg-4 col-lg-4 col-xl-3">
                      <ul>
                        {chunk.map((item) => (
                          <li key={item.id}>
                            <Link to={`/${item.id}`}>{item.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                    {/* <div className=" mb-lg-4 col-lg-4 col-xl-3">
                      <ul>
                        <li>
                          <Link to="/">IT / Softwer Agency</Link>
                        </li>
                        <li>
                          <Link to="/index-2">SaaS App Landing</Link>
                        </li>
                        <li>
                          <Link to="/index-3">Payments Solution</Link>
                        </li>
                        <li>
                          <Link to="/index-4"> Software Company</Link>
                        </li>
                        <li>
                          <Link to="/index-5">Artificial Intelligence</Link>
                        </li>
                        <li>
                          <Link to="/index-6">NFT Service</Link>
                        </li>
                        <li>
                          <Link to="/index-7">Cyber Security</Link>
                        </li>
                        <li>
                          <Link to="/index-8">Hardware Service</Link>
                        </li>
                        <li>
                          <Link to="/index-9">ICO Landing</Link>
                        </li>
                        <li>
                          <Link to="/index-10">Personal Portfolio</Link>
                        </li>
                        <li>
                          <Link to="/index-11">SEO Service</Link>
                        </li>
                      </ul>
                    </div>
                    <div className=" mb-lg-4 col-lg-4 col-xl-3">
                      <ul>
                        <li>
                          <Link to="/service">Service 01</Link>
                        </li>
                        <li>
                          <Link to="/service-2">Service 02</Link>
                        </li>
                        <li>
                          <Link to="/service-3">Service 03</Link>
                        </li>
                        <li>
                          <Link to="/service-4">Service 04</Link>
                        </li>
                        <li>
                          <Link to="/service-5">Service 05</Link>
                        </li>
                        <li>
                          <Link to="/service-details">Service Single</Link>
                        </li>
                      </ul>
                    </div>
                    <div className=" mb-lg-4 col-lg-4 col-xl-3">
                      <ul>
                        <li>
                          <Link to="/project">Project 01</Link>
                        </li>
                        <li>
                          <Link to="/project-2">Project 02</Link>
                        </li>
                        <li>
                          <Link to="/project-3">Project 03</Link>
                        </li>
                        <li>
                          <Link to="/project-details">Case Study Details</Link>
                        </li>
                        <li>
                          <Link to="/pricing">Pricing 01</Link>
                        </li>
                        <li>
                          <Link to="/pricing-2">Pricing 02</Link>
                        </li>
                      </ul>
                    </div>
                    <div className=" mb-lg-4 col-lg-4 col-xl-3">
                      <ul>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/team">Team 01</Link>
                        </li>
                        <li>
                          <Link to="/team-2">Team 02</Link>
                        </li>
                        <li>
                          <Link to="/team-3">Team 03</Link>
                        </li>
                        <li>
                          <Link to="/team-details">Team Details</Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </li>
              <li className="menu-item-has-children">
                <Link to="/blog">Resources</Link>
                {/* <ul className="sub-menu">
                  <li>
                    <Link to="/service">Service 01</Link>
                  </li>
                  <li>
                    <Link to="/service-2">Service 02</Link>
                  </li>
                  <li>
                    <Link to="/service-3">Service 03</Link>
                  </li>
                  <li>
                    <Link to="/service-4">Service 04</Link>
                  </li>
                  <li>
                    <Link to="/service-5">Service 05</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Service Single</Link>
                  </li>
                </ul> */}
              </li>
              <li className="menu-item-has-children">
                <Link to="/pricing">Pricing</Link>
                {/* <ul className="sub-menu">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/team">Team 01</Link>
                  </li>
                  <li>
                    <Link to="/team-2">Team 02</Link>
                  </li>
                  <li>
                    <Link to="/team-3">Team 03</Link>
                  </li>
                  <li>
                    <Link to="/team-details">Team Details</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing 01</Link>
                  </li>
                  <li>
                    <Link to="/pricing-2">Pricing 02</Link>
                  </li>
                </ul> */}
              </li>

              <li className="menu-item-has-children">
                <Link to="#" style={{color:'#5CA6D8'}}>Support</Link>
                {/* <ul className="sub-menu">
                  <li>
                    <Link to="/blog">Blog 01</Link>
                  </li>
                  <li>
                    <Link to="/blog-2">Blog 02</Link>
                  </li>
                  <li>
                    <Link to="/blog-3">Blog 03</Link>
                  </li>
                  <li>
                    <Link to="/blog-4">Blog 04</Link>
                  </li>
                  <li>
                    <Link to="/blog-details">Blog Details</Link>
                  </li>
                </ul> */}
              </li>
              <li>
                <Link to="/contact" style={{color:'#5CA6D8'}}>Sign In</Link>
              </li>
              <li>
                <Link to="/contact" style={{color:'#5CA6D8'}}>Book A Demo</Link>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <Link className="btn btn-border-base" to="/about">
              Try For Free
            </Link>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;
