let serviceList = [
  {
    title: "Healthcare",
    des: "Monitor staff qualifications and create complex shift patterns to ensure quality and compliance.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Events",
    des: "Create shift templates in the events calendar and track attendance on multiple job sites.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Staffing",
    des: "Export NFC tag-based tour reports and ensure lone worker safety with automatic check calls.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Security",
    des: "Grow Your business with a database and shift scheduling software for hourly employees.",
    img: "assets/img/service-icon/2.png",
  },
];

export default serviceList;
