import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";

const testimonialData = [
  {
    name: 'John Doe',
    designation: 'Operations Manager',
    review: 'RosterInsight has transformed our scheduling process, making it more efficient and transparent. The time clock feature ensures accurate payroll, and the support team is always responsive to our needs.',
    icon: 'assets/img/icon/25.png',
    image: 'assets/img/testimonial/1.png',
    rating: 5
  },
  {
    name: 'Jane Smith',
    designation: 'HR Director',
    review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
    icon: 'assets/img/icon/25.png',
    image: 'assets/img/testimonial/2.png',
    rating: 5
  },
];

const pricingPlanData = {
  subtitle: "Pricing",
  title: "Affordable Plans for Every Business",
  content:
    "Choose a plan that fits your business size and needs. With flexible pricing and a feature-rich platform, RosterInsight is designed to grow with your team.",
  buttons: [
    {
      label: "View Pricing",
      link: "/pricing",
      className: "btn btn-border-base",
    },
    {
      label: "Get Started Now",
      link: "/about",
      className: "btn btn-black",
      style: { marginLeft: "8px" },
    },
  ],
};

const featuredata = [
  {
    id: 1,
    title: "Efficient Scheduling at Your Fingertips",
    subtitle: "Scheduling",
    description: "Scheduling shifts and managing rosters has never been easier. With RosterInsight, you can automate the process, reduce errors, and ensure everyone is where they need to be, when they need to be there.",
    image: "assets/img/about/10.png", // Replace with actual image path
    listItems: [
      "Drag-and-drop scheduling interface",
      "Real-time shift updates",
      "Automated notifications for employees",
      "Customizable shift patterns",
      "Integration with payroll systems"
    ]
  },
  {
    id: 2,
    title: "Accurate Time Tracking Made Simple",
    subtitle: "Time Clock",
    description: "With RosterInsight’s Time Clock feature, you can keep track of your team's hours, breaks, and overtime seamlessly. Eliminate manual timekeeping and ensure precise payroll calculations with our automated system.",
    image: "assets/img/about/10.png", // Replace with actual image path
    listItems: [
      "Clock in/out from any device",
      "Real-time attendance tracking",
      "Automated time reports",
      "Compliance with labor laws",
      "Integrated with payroll systems"
    ]
  },
  {
    id: 3,
    title: "Manage Your Workforce On-the-Go",
    subtitle: "Mobile App",
    description: "The RosterInsight mobile app gives you full control over your team from the palm of your hand. Schedule shifts, track attendance, and communicate with staff, all through a user-friendly mobile experience.",
    image: "assets/img/about/10.png", // Replace with actual image path
    listItems: [
      "Instant notifications for shift changes",
      "Manage schedules remotely",
      "View real-time employee status",
      "Secure and user-friendly interface",
      "Available on iOS and Android"
    ]
  },
  {
    id: 4,
    title: "Simplified Payroll and Invoicing",
    subtitle: "Pay & Charge",
    description: "Our Pay & Charge feature ensures that payroll and invoicing processes are accurate and efficient. With RosterInsight, you can streamline payment management, automate invoicing, and reduce errors.",
    image: "assets/img/about/10.png", // Replace with actual image path
    listItems: [
      "Automated payroll calculations",
      "Easy invoice generation",
      "Integration with financial systems",
      "Time and expense tracking",
      "Custom payment cycles"
    ]
  }
];

const Home = () => {
  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <BannerTwo />

      {/* Counter Area Two */}
      <CounterAreaTwo />

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      {/* About Area Two */}
      {/* <ServiceAreaTwo /> */}

      {/* Pricing Area Two */}
      {/* <PricingAreaTwo /> */}



      {/* Contact Area Two */}
      {/* <ContactAreaTwo /> */}

      {/* Work Process Two */}
      {/* <WorkProcessTwo /> */}

      {/* Testimonial One */}
      <TestimonialOne testimonialData={testimonialData}/>

      <Tour_PricingPlan data={pricingPlanData}/>

      {/* Service Area One */}
      <ServiceAreaGroup serviceList={serviceList}/>

      {/* Blog Area Two */}
      <BlogAreaTwo />

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Home;
