
import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";
import Tour_SubBanner from "../components/Tour_SubBanner";
import ServiceAreaSix from "../components/ServiceAreaSix";
import Tour_IndustryInsights from "../components/Tour_IndustryInsights";
import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FaqAreaThree from "../components/FaqAreaThree";


const bannerData = [
    {
      subtitle: 'Event Staff Scheduling ',
      title: 'Efficient Event Staff Management with RosterInsight',
      description:
        'Easily manage and schedule your event staff with RosterInsight. Publish shifts in your event calendar, monitor position coverage, and optimize staff allocation for smooth event execution.',
      image: 'assets/img/banner/Schedule.jpg',
      buttons: [
        { text: 'Try For Free', link: '/contact', className: 'btn btn-border-base' },
        { text: 'Book A Demo', link: '/contact', className: 'btn btn-black' }
      ]
    }
  ];

const testimonialData = [
    {
      name: 'John Doe',
      designation: 'Operations Manager',
      review: 'RosterInsight has transformed our scheduling process, making it more efficient and transparent. The time clock feature ensures accurate payroll, and the support team is always responsive to our needs.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/1.png',
      rating: 5
    },
    {
      name: 'Jane Smith',
      designation: 'HR Director',
      review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/2.png',
      rating: 5
    },
  ];

  const services = [
    
    {
      title: 'Streamlined Operations',
      description:
        'Automating scheduling and time tracking reduces manual workloads, allowing managers to allocate time to strategic tasks.',
      image: 'assets/img/home-6/1.png',
      link: '/service-details',
    },
    {
      title: 'Enhanced Employee Satisfaction',
      description:
        'Providing a user-friendly platform for shift management and time tracking boosts employee engagement and morale.',
      image: 'assets/img/home-6/2.png',
      link: '/service-details',
    },
    {
      title: 'Cost Optimization',
      description:
        'Efficient scheduling and payroll management contribute to significant cost savings, enhancing overall profitability.',
      image: 'assets/img/home-6/3.png',
      link: '/service-details',
    },
  ];

  const featuredata = [

    {
      id: 2,
      subtitle: "Event Calendar",
      title: "Create and Manage Shifts Directly in Your Event Calendar",
      description: "Easily organize and monitor multi-day events while ensuring that each position is adequately staffed. If your events have recurring staffing needs, create templates or copy and paste event schedules for efficiency.",
      image: "assets/img/event-calendar.png", // Replace with actual image path
      listItems: [
        "Monitor position coverage",
        "Manage multi-day events",
        "Copy and paste events for ease"
      ]
    },
    {
      id: 3,
      subtitle: "Staff App",
      title: "Post Open Shifts and Let Staff Apply Directly from the App",
      description: "Publish available shifts to staff members with matching skills in real time. Let your staff apply from their mobile devices, filling shifts on a first-come, first-served basis.",
      image: "assets/img/staff-app.png", // Replace with actual image path
      listItems: [
        "Publish shifts in bulk",
        "Allow staff self-scheduling via app",
        "Quickly fill shifts"
      ]
    },
    {
      id: 4,
      subtitle: "Staff Database",
      title: "Unlimited Staff Database with No User Limits",
      description: "Recruit without restrictions and ensure seamless position coverage with our unlimited staff database. You only pay for the actual scheduled hours, helping mitigate demand fluctuations and reduce financial risks.",
      image: "assets/img/staff-database.png", // Replace with actual image path
      listItems: [
        "Ensure consistent position coverage",
        "Pay only for scheduled hours",
        "Minimize financial risks"
      ]
    },
    {
      id: 5,
      subtitle: "Time and Attendance",
      title: "Track Time and Automate Payroll Effortlessly",
      description: "Utilize GPS Time Clock to accurately track time and automate payroll. Approve timesheets in bulk and export them instantly. If managing staff for clients, automated invoicing is also available.",
      image: "assets/img/time-attendance.png", // Replace with actual image path
      listItems: [
        "Accurate time tracking with location-based GPS",
        "Custom pay and billing rates for each shift",
        "Automated payroll and invoicing"
      ]
    }
  ];
  

  const ctaActionData = {
    subtitle: "",
    title: "Create extended schedules and automate shift assignments.",
    // content:
    //   "Experience the benefits of RosterInsight's Employee Scheduling Software. Contact us today to schedule a demo or start your free trial.",
    buttons: [
      // {
      //   label: "Contact Us",
      //   link: "/contact",
      //   className: "btn btn-border-base",
      // },
      {
        label: "Start Free Trial",
        link: "/contact",
        className: "btn btn-black",
        style: { marginLeft: "8px" },
      },
    ],
  };


  const industrydata = [
    { title: "Efficient Steward Management at Football Matches: Best Practices" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "8 Benefits of Using a GPS Time Clock App" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "How Staffing Agencies Can Streamline Payroll Tracking with Automation" ,imgSrc: "assets/img/blog/1.png",link: "/"},
  ];

  const faqData = [
    {
      question: "What is staff scheduling software?",
      answer: "Staff scheduling software helps automate the scheduling of your employees, reducing administrative work. It enables you to create detailed schedules quickly, assign shifts to the right people, track attendance, and manage costs to ensure profitability."
    },
    {
      question: "Can I track payroll information with RosterInsights?",
      answer: "Absolutely! With RosterInsights, you can set up custom pay rates for each shift. When your employees clock in using the GPS Time Clock app, timesheets containing payroll details are automatically generated."
    },
    {
      question: "Can I manage break times with RosterInsights?",
      answer: "Yes, you can customize break rules in RosterInsights, allowing employees to clock in and out of breaks using the GPS Time Clock app on their mobile devices."
    },
    {
      question: "What other benefits does RosterInsights provide?",
      answer: "RosterInsights helps you manage absences and track holiday pay, with the added benefit of setting custom working hours rules to ensure compliance with labor regulations."
    },
    {
      question: "Is RosterInsights a good solution for staffing agencies?",
      answer: "Yes, RosterInsights is an excellent solution for staffing agencies. It includes specialized features like Pay & Charge and Client Portal, designed specifically for your needs."
    },
    {
      question: "How is RosterInsights different from other staff management systems?",
      answer: "RosterInsights offers an advanced scheduling system that optimizes staffing for larger enterprises, reducing administrative overhead. It’s user-friendly and charges only for scheduled hours, providing risk-free management of temporary workers and supporting growth opportunities for mid-sized companies."
    }
  ];
  

const Tour_EventManagement = () => {

  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <Tour_SubBanner bannerData={bannerData}/>

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      <ServiceAreaSix services={services} title="Why Use InsightRota App? Top 3 Benefits"/>

      {/* Testimonial One */}
      <TestimonialOne testimonialData={testimonialData}/>

      <Tour_PricingPlan data={ctaActionData}/>
      
      
      <Tour_IndustryInsights data={industrydata} title="Industries Finding Success with Our Staff Scheduling Software"/>

      {/* Blog Area Two */}
      {/* <BlogAreaTwo /> */}

      <FaqAreaOne data={faqData}/>

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Tour_EventManagement;
