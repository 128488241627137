
import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";
import Tour_SubBanner from "../components/Tour_SubBanner";
import ServiceAreaSix from "../components/ServiceAreaSix";
import Tour_IndustryInsights from "../components/Tour_IndustryInsights";
import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FaqAreaThree from "../components/FaqAreaThree";


const bannerData = [
    {
      subtitle: 'Absence Management',
      title: 'Streamlined absence and leave management tools',
      description:
        'Get instant notifications for leave requests.Enable swift approvals and simplify holiday pay with automation.',
      image: 'assets/img/banner/Schedule.jpg',
      buttons: [
        { text: 'Try For Free', link: '/contact', className: 'btn btn-border-base' },
        { text: 'Book A Demo', link: '/contact', className: 'btn btn-black' }
      ]
    }
  ];

const testimonialData = [
    {
      name: 'John Doe',
      designation: 'Operations Manager',
      review: 'RosterInsight has transformed our scheduling process, making it more efficient and transparent. The time clock feature ensures accurate payroll, and the support team is always responsive to our needs.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/1.png',
      rating: 5
    },
    {
      name: 'Jane Smith',
      designation: 'HR Director',
      review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/2.png',
      rating: 5
    },
  ];

  const services = [
    
    {
      title: 'Streamlined Operations',
      description:
        'Automating scheduling and time tracking reduces manual workloads, allowing managers to allocate time to strategic tasks.',
      image: 'assets/img/home-6/1.png',
      link: '/service-details',
    },
    {
      title: 'Enhanced Employee Satisfaction',
      description:
        'Providing a user-friendly platform for shift management and time tracking boosts employee engagement and morale.',
      image: 'assets/img/home-6/2.png',
      link: '/service-details',
    },
    {
      title: 'Cost Optimization',
      description:
        'Efficient scheduling and payroll management contribute to significant cost savings, enhancing overall profitability.',
      image: 'assets/img/home-6/3.png',
      link: '/service-details',
    },
  ];

  const featuredata = [
    {
      id: 1,
      subtitle: "Custom Absence Types",
      title: "Tailor your staff leave planner to fit your requirements",
      description: "Define annual leave allowances and set up accrual rates. Add local public holidays and create personalized absence categories, such as sick days or wellness days. Specify whether these are paid or unpaid to streamline absence requests and simplify management.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Minimize human errors",
        "Ensure clear and transparent absence policies",
        "Simplify and speed up the process"
      ]
    },
    {
      id: 2,
      subtitle: "Staff App",
      title: "Mobile solution for managing staff holidays",
      description: "Eliminate the need for constant communication through calls, emails, or messages. Allow employees to request absences and schedule holidays directly from their mobile devices. Get real-time notifications and provide approvals effortlessly.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Enhance convenience for employees",
        "Process requests in real-time",
        "Cut down on administrative tasks"
      ]
    },
    {
      id: 3,
      subtitle: "Absence Calendar",
      title: "Stay organized with a comprehensive absence tracker",
      description: "The absence calendar provides a clear view of all employee absences. Easily filter by employment type, roles, or absence status to quickly identify requests requiring action. Save custom filter settings to streamline future processes.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Visualize absence timelines",
        "Save custom filter configurations",
        "Access actionable insights instantly"
      ]
    },
    {
      id: 4,
      subtitle: "Absence Reports",
      title: "Make informed decisions with actionable absence insights",
      description: "Analyze absence data to identify trends and assess productivity. Sort, filter, and export summaries to better understand your workforce and make strategic decisions that improve team efficiency.",
      image: "assets/img/about/10.png", // Replace with actual image path
      listItems: [
        "Deliver real-time scheduling updates",
        "Streamline timesheet approvals",
        "Ensure transparent charge rate details"
      ]
    }
  ];
  

  const ctaActionData = {
    subtitle: "",
    title: "Effortless Absence Management with Automated Holiday Pay",
    // content:
    //   "Experience the benefits of RosterInsight's Employee Scheduling Software. Contact us today to schedule a demo or start your free trial.",
    buttons: [
      {
        label: "Contact Us",
        link: "/contact",
        className: "btn btn-border-base",
      },
      {
        label: "Start Free Trial",
        link: "/contact",
        className: "btn btn-black",
        style: { marginLeft: "8px" },
      },
    ],
  };


  const industrydata = [
    { title: "Payroll Errors? Tips to Prevent Them Moving Forward" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "9 Challenges Solved by Absence Management Software" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "The Benefits of Adopting Time and Labor Management Software" ,imgSrc: "assets/img/blog/1.png",link: "/"},
  ];

  const faqData = [
    {
      question: "What is absence management software?",
      answer:
        "Roster Insights' Absence Management allows employees to submit sick day requests and plan holidays seamlessly. Managers receive real-time updates and can approve requests swiftly. This feature is part of the Roster Insights Staff Scheduling Software suite."
    },
    {
      question: "What is staff scheduling software?",
      answer:
        "Staff scheduling software streamlines the scheduling process by automating employee rosters, reducing administrative tasks. It enables the creation of complex schedules in seconds, quickly fills shifts with the right team members, tracks time and attendance, and manages costs to ensure profitability."
    },
    {
      question: "Can I track payroll information with Roster Insights?",
      answer:
        "Absolutely! You can assign custom pay rates for each shift. Using the GPS-enabled Time Clock app, employees’ clock-ins generate timesheets with payroll details automatically."
    },
    {
      question: "Can I manage break times with Roster Insights?",
      answer:
        "Yes! Break rules can be fully customized, and employees can clock in and out for breaks through the GPS Time Clock app on their mobile devices."
    },
    {
      question: "What additional benefits does Roster Insights offer?",
      answer:
        "Roster Insights helps manage absences and tracks holiday pay effortlessly. It also allows you to configure custom working time rules, ensuring compliance with labor regulations."
    },
    {
      question: "Is Roster Insights suitable for staffing agencies?",
      answer:
        "Absolutely! Roster Insights is an ideal choice for staffing agencies, offering specialized features like Pay & Charge management and a dedicated Client Portal to cater to agency-specific needs."
    },
    {
      question: "How does Roster Insights stand out from other staff management systems?",
      answer:
        "Roster Insights provides an advanced scheduling solution designed to optimize operations for large enterprises by reducing administrative workload and costs. At the same time, it is user-friendly, with a pay-as-you-go pricing model based on scheduled hours. This approach ensures flexibility and growth opportunities, particularly for mid-sized businesses managing temporary workforces."
    }
  ];
  
  

const Tour_AbsenceManagement = () => {

  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <Tour_SubBanner bannerData={bannerData}/>

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      {/* <ServiceAreaSix services={services} title="Why Use InsightRota App? Top 3 Benefits"/> */}

      {/* Testimonial One */}
      <TestimonialOne testimonialData={testimonialData}/>

      <Tour_PricingPlan data={ctaActionData}/>
      
      
      <Tour_IndustryInsights data={industrydata} title="Industry Trends and Solutions"/>

      {/* Blog Area Two */}
      {/* <BlogAreaTwo /> */}

      <FaqAreaOne data={faqData}/>

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Tour_AbsenceManagement;
