
import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";
import Tour_SubBanner from "../components/Tour_SubBanner";
import ServiceAreaSix from "../components/ServiceAreaSix";
import Tour_IndustryInsights from "../components/Tour_IndustryInsights";
import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FaqAreaThree from "../components/FaqAreaThree";


const bannerData = [
    {
      subtitle: 'NFC Time Tracking',
      title: 'RosterInsight NFC Time Tracking for Remote UK Employees',
      description:
        'RosterInsights NFC time-tracking solution allows you to set up NFC tags as checkpoints, enabling you to create security guard tours and patrol routes with precise real-time data. Ensure your team members are at their designated locations to maintain the highest level of service for your clients.',
      image: 'assets/img/banner/Schedule.jpg',
      buttons: [
        { text: 'Try For Free', link: '/contact', className: 'btn btn-border-base' },
        { text: 'Book A Demo', link: '/contact', className: 'btn btn-black' }
      ]
    }
  ];

const testimonialData = [
    {
      name: 'John Doe',
      designation: 'Operations Manager',
      review: 'RosterInsight has transformed our scheduling process, making it more efficient and transparent. The time clock feature ensures accurate payroll, and the support team is always responsive to our needs.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/1.png',
      rating: 5
    },
    {
      name: 'Jane Smith',
      designation: 'HR Director',
      review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/2.png',
      rating: 5
    },
  ];

  const services = [
    
    {
      title: 'Ensure Total Coverage',
      description:
        'Verify that your employees are always present at their assigned locations. Set up guard tours and patrol routes to ensure full coverage at all job sites at all times. Access real-time data for each checkpoint, allowing you to make informed decisions and respond swiftly when necessary.',
      image: 'assets/img/home-6/1.png',
      link: '/service-details',
    },
    {
      title: 'Streamlined Workflows for Staff',
      description:
        'Simplify NFC time tracking for your staff. Employees only need their mobile devices to check in at each NFC or iBeacon tag—no special hardware or expertise is required from either managers or staff for a seamless time-tracking experience.',
      image: 'assets/img/home-6/2.png',
      link: '/service-details',
    },
    {
      title: 'Enhance Your Service',
      description:
        'Provide timestamped data for every checkpoint, patrol route, and security guard tour to your clients as a verified standard of service. Use this data to optimize your routes and checkpoints, offering an even more efficient and reliable security service.',
      image: 'assets/img/home-6/3.png',
      link: '/service-details',
    },
  ];

  const featuredata = [
    {
      subtitle: "Checkpoints & Routes",
      title: "Create and Monitor Secure Patrol Routes",
      description: "Set up NFC or iBeacon tags at key locations with specified timeframes for employee check-ins. This ensures that every patrol route is accurately tracked and the designated areas are consistently covered.",
      image: "assets/img/about/10.png", // Replace with actual image path

    },
    {
      subtitle: "Mobile App",
      title: "Simplify Operations with Parim Mobile App",
      description: "Streamline the work of your security team with the Parim mobile app. Allow employees to check in at checkpoints directly from their mobile devices, reducing the need for extra communication and making the process more efficient.",
      image: "assets/img/about/10.png", // Replace with actual image path

    },
    {
      subtitle: "Real-Time Data",
      title: "Deliver Instant Updates to Your Clients",
      description: "Receive real-time data on every checkpoint, offering timestamped reports to clients. This provides proof that all designated locations have been visited by your security team, ensuring accountability and transparency.",
      image: "assets/img/about/10.png", // Replace with actual image path

    }
  ];
  

  const ctaActionData = {
    subtitle: "",
    title: "How does RosterInsight use NFC for time tracking?",
    content:
      "RosterInsight utilizes NFC time tracking by placing NFC tags at various job locations. Employees use the RosterInsight app on their mobile devices to check in at each NFC tag. Managers receive real-time updates for every checkpoint, ensuring complete monitoring of all job sites. This system helps streamline security guard tours and patrol routes, while providing clients with verifiable coverage data.",
    buttons: [
      // {
      //   label: "Contact Us",
      //   link: "/contact",
      //   className: "btn btn-border-base",
      // },
      {
        label: "Start Free Trial",
        link: "/contact",
        className: "btn btn-black",
        style: { marginLeft: "8px" },
      },
    ],
  };

  const ctaActionData1 = {
    subtitle: "",
    title: "Achieve UK labor law compliance with our staff scheduling system.",
    content:
      "RosterInsight workforce management software guarantees adherence to working time regulations and other labor laws, ensuring full compliance.",
    // buttons: [
    //   {
    //     label: "Contact Us",
    //     link: "/contact",
    //     className: "btn btn-border-base",
    //   },
    //   {
    //     label: "Start Free Trial",
    //     link: "/contact",
    //     className: "btn btn-black",
    //     style: { marginLeft: "8px" },
    //   },
    // ],
  };


  const industrydata = [
    { title: "Security" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "HealthCare" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Events" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Stadia" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Temporary Staffing" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Venues" ,imgSrc: "assets/img/blog/1.png",link: "/"},
  ];

  const faqData = [
    {
      question: "How far can the RosterInsight app track an NFC tag?",
      answer: "NFC tags work within a maximum proximity of 4 cm. To ensure proper registration, we recommend physically touching the NFC tags with mobile devices to register the checkpoint."
    },
    {
      question: "What are iBeacon tags?",
      answer: "iBeacon tags are Bluetooth-enabled location trackers. They work using Bluetooth Low Energy (BLE) to broadcast their identifier to nearby devices. Unlike NFC tags, iBeacons do not require physical contact to register the location."
    },
    {
      question: "What’s the difference between iBeacon and NFC time tracking?",
      answer: "NFC tags require close contact with a mobile device, while iBeacon tags work based on proximity and do not need physical contact. Use NFC tags when precise location tracking is necessary, and iBeacons when discretion is needed, allowing employees to patrol without touching their devices."
    },
    {
      question: "How do I set up the RosterInsight app on a tablet?",
      answer: "Download the RosterInsight app from the App Store (for iPads) or Google Play (for Android tablets). Log in with your RosterInsight account, and you'll have access to all features, including NFC or iBeacon check-ins."
    },
    {
      question: "Which tablet should I use?",
      answer: "Any Apple or Android tablet is compatible with RosterInsight NFC time tracking. Simply download the RosterInsight app, log in, and you're ready to use NFC tags to clock in at designated checkpoints."
    }
  ];
  

const Tour_GuardToursNFC = () => {

  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <Tour_SubBanner bannerData={bannerData}/>

      <ServiceAreaSix services={services} title="Why Choose RosterInsight's NFC Time Tracking App?" description="Ensure comprehensive job location coverage with NFC time tracking.With RosterInsight's security scheduling software, you can manage all your security staffing operations from one central platform."/>

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      <Tour_PricingPlan data={ctaActionData}/>


      {/* Testimonial One */}
      <TestimonialOne testimonialData={testimonialData}/>

      
      
      {/* <Tour_IndustryInsights data={industrydata} title="Industries Finding Success with Our Staff Scheduling Software"/> */}


      <Tour_PricingPlan data={ctaActionData1}/>


      {/* Blog Area Two */}
      <BlogAreaTwo />

      <FaqAreaOne data={faqData}/>

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Tour_GuardToursNFC;
