import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
const Tour_SubBanner = ({bannerData}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== Tour_SubBanner Start ==================*/}
      <div
        className="banner-area-60 bg-relative banner-area-2 bg-cover"
        // className="banner-area bg-relative banner-area-2 bg-cover"
        // style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
      >
           <div className="container">
            <div className="row d-flex justify-content-center text-center">
              {bannerData.map((item, index) => (
                <div key={index} className="col-lg-10 mb-2">
                  <div className="banner-inner">
                    <h6
                      className="subtitle"
                      data-aos="fade-right"
                      data-aos-delay="100"
                      data-aos-duration="1500"
                    >
                      {item.subtitle}
                    </h6>
                    <h2
                      className="title"
                      data-aos="fade-right"
                      data-aos-delay="200"
                      data-aos-duration="1500"
                    >
                      {item.title}
                    </h2>
                    <p
                      className="content"
                      data-aos="fade-right"
                      data-aos-delay="250"
                      data-aos-duration="1500"
                    >
                      {item.description}
                    </p>
                    {item.buttons.map((button, i) => (
                      <Link
                        key={i}
                        className={button.className}
                        data-aos="fade-right"
                        data-aos-delay={300 + i * 50}
                        data-aos-duration="1500"
                        to={button.link}
                        style={i === 1 ? { marginLeft: '8px' } : {}}
                      >
                        {button.text}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
              {bannerData.map((item, index) => (
                <div
                  key={index}
                  className="col-lg-10 col-md-9 mt-5"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="banner-thumb-2">
                    <img className="main-img" src={item.image} alt="img" />
                  </div>
                </div>
              ))}
            </div>
          </div>
      </div>

      {/* ================== Tour_SubBanner End ==================*/}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="XM6kTQPzzpQ"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Tour_SubBanner;
