
import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import AboutArea from "../components/AboutArea";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import serviceList from "../scripts/serviceList";
import Tour_PricingPlan from "../components/Tour_PricingPlan";
import Tour_SubBanner from "../components/Tour_SubBanner";
import ServiceAreaSix from "../components/ServiceAreaSix";
import Tour_IndustryInsights from "../components/Tour_IndustryInsights";
import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FaqAreaThree from "../components/FaqAreaThree";


const bannerData = [
    {
      subtitle: 'Employee Time Tracking with RosterInsight',
      title: 'Efficient Time Tracking for Your Team with Roster Insights',
      description:
        'Say goodbye to paper timesheets and manual errors. Roster Insights offers a seamless way to track work hours and attendance in real-time with our top-tier time tracking software.',
      image: 'assets/img/banner/Schedule.jpg',
      buttons: [
        { text: 'Try For Free', link: '/contact', className: 'btn btn-border-base' },
        { text: 'Book A Demo', link: '/contact', className: 'btn btn-black' }
      ]
    }
  ];

const testimonialData = [
    {
      name: 'John Doe',
      designation: 'Operations Manager',
      review: 'RosterInsight has transformed our scheduling process, making it more efficient and transparent. The time clock feature ensures accurate payroll, and the support team is always responsive to our needs.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/1.png',
      rating: 5
    },
    {
      name: 'Jane Smith',
      designation: 'HR Director',
      review: 'The intuitive interface and comprehensive features of RosterInsight have streamlined our workforce management. The ability to manage absences and holidays seamlessly has been a game-changer for our organization.',
      icon: 'assets/img/icon/25.png',
      image: 'assets/img/testimonial/2.png',
      rating: 5
    },
  ];

  const services = [
    
    {
      title: 'Monitor Employee Attendance',
      description:
        'Stay updated in real-time about who’s starting on time, who’s running late, and who’s clocking out early. Use GPS geofencing to verify attendance and ensure accuracy.',
      image: 'assets/img/home-6/1.png',
      link: '/service-details',
    },
    {
      title: 'Track Every Hour Worked',
      description:
        'Allow employees to clock in and out using Roster Insights software to record precise working hours. Receive accurate timesheets for payroll and invoicing. Eliminate manual tracking and focus on what really matters.',
      image: 'assets/img/home-6/2.png',
      link: '/service-details',
    },
    {
      title: 'Streamline Payroll Processing',
      description:
        'Let our time-tracking software handle complex salary calculations for you. Approve timesheets in bulk and seamlessly export them to your payroll system or client billing system. Say goodbye to errors and delays.',
      image: 'assets/img/home-6/3.png',
      link: '/service-details',
    },
  ];

  const featuredata = [
    {
      id: 1,
      title: "Real-Time Attendance Monitoring",
      subtitle: "Live Attendance Data",
      description:
        "Track your employees’ attendance in real-time with GPS geofencing. Get notified whenever an employee clocks in or out, takes a break, or is late. Supervisors and managers receive immediate updates, ensuring accurate attendance records.",
      image: "assets/img/about/10.png", // Replace with actual image path
    },
    {
      id: 2,
      title: "Effortless Break Time Management",
      subtitle: "Simplify Break Time Tracking",
      description:
        "Ensure compliance with local labor laws by setting paid and unpaid break times. Employees can easily log their break periods directly through our Time Clock app, streamlining the entire process.",
      image: "assets/img/about/10.png", // Replace with actual image path
    },
    {
      id: 3,
      title: "Quick and Easy Payroll Automation",
      subtitle: "Automate Pay Calculations",
      description:
        "Set custom pay rules with additional rates for holidays, overtime, and more. Our software automates these calculations, saving you time. Approve timesheets in bulk and export them seamlessly to your payroll system.",
      image: "assets/img/about/10.png", // Replace with actual image path
    },
    {
      id: 4,
      title: "User-Friendly Time Clock App",
      subtitle: "Track Time with Ease",
      description:
        "Allow your employees to clock in and out directly from their mobile devices using the Roster Insights app. They can also manage break times, view schedules, and access their tasks all in one place.",
      image: "assets/img/about/10.png", // Replace with actual image path
    },
  ];
  

  const ctaActionData = {
    subtitle: "",
    title: "Stay Compliant with UK Labor Laws Using Our Time Tracker",
    content:
      "Customize working time rules to align with local labor regulations, and let our online time tracker automatically ensure full compliance.",
    buttons: [
      {
        label: "Guarantee Compliance with Labor Laws",
        link: "/contact",
        className: "btn btn-black",
      },
      // {
      //   label: "Start Free Trial",
      //   link: "/contact",
      //   className: "btn btn-border-base",
      //   style: { marginLeft: "8px" },
      // },
    ],
  };


  const industrydata = [
    { title: "Security" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "HealthCare" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Events" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Stadia" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Temporary Staffing" ,imgSrc: "assets/img/blog/1.png",link: "/"},
    { title: "Venues" ,imgSrc: "assets/img/blog/1.png",link: "/"},
  ];

  const faqData = [
    {
      question: "What are the benefits of using time tracking?",
      answer:
        "Time tracking provides managers with real-time insights into employees' working hours, attendance, and break times. This ensures employees are present at their job sites and automates timesheet creation and salary calculations, boosting productivity and reducing manual errors."
    },
    {
      question: "How do employees track their working hours?",
      answer:
        "Employees can clock in and out using their mobile devices, tablets, or computers. They can also log break times, with each action updated in real time for managers. To ensure security, accounts are protected by 2-step verification to prevent fraudulent activities."
    },
    {
      question: "How can I track the location of my employees?",
      answer:
        "You can use geofencing features for time tracking, which require employees to enable GPS and location services on their devices. This ensures that employees can only clock in and out at the designated job sites, ensuring accurate attendance records."
    },
    {
      question: "How are employee timesheets created?",
      answer:
        "Employee timesheets are automatically generated based on their clock-in and clock-out times. Managers can easily access and review timesheets in the Roster Insights platform, where they can approve and export them in bulk. Filters can also be applied to view specific timesheets by employee, location, or department."
    },
    {
      question: "How are employee salaries calculated?",
      answer:
        "Salaries are calculated based on customizable pay rules for each shift, position, or employee. When employees clock in and out, Roster Insights automatically calculates their pay and updates their timesheets, which can then be exported to payroll providers in bulk."
    },
    {
      question: "How do I get started with Roster Insights?",
      answer:
        "To begin, create an account and start a free trial. Roster Insights' desktop platform operates within your browser, so there's no need for a download. For mobile access, download the Roster Insights app from the App Store or Google Play. Managers can create employee profiles and provide login credentials for access across devices."
    }
  ];
  

const Tour_TimeClock = () => {

  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <Tour_SubBanner bannerData={bannerData}/>


      <ServiceAreaSix services={services} title="Why Choose Roster Insights' Time Tracker App? Key Benefits"/>

      {/* About Area Two */}
      <AboutArea data={featuredata}/>

      {/* Testimonial One */}
      {/* <TestimonialOne testimonialData={testimonialData}/> */}

      <Tour_PricingPlan data={ctaActionData}/>
      
      
      <Tour_IndustryInsights data={industrydata} title="Industries Finding Success with Our Staff Scheduling Software"/>

      {/* Blog Area Two */}
      <BlogAreaTwo />

      <FaqAreaOne data={faqData}/>

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default Tour_TimeClock;
